import React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { Section as DefaultSection } from '../section';
import { ContentWrapper } from '../common';
import arrowSvg from '../../images/svg/arrow-left.svg';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { ANCHOR_CONTACT } from '../../routes';
import compassSrc from '../../images/compass.png';

const ArrowIcon = styled(arrowSvg)`
  fill: ${({theme}) => theme.colors.white};
  transform: rotateZ(-180deg);
`;

const Bullet = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 100px;
  background-color: ${({theme}) => theme.colors.primary};
  transition: all ease 0.3s;
`;

const Section = styled(DefaultSection)`
  padding-top: 40px;
  padding-bottom: 60px;

  a, a:hover {
    color: ${({theme}) => theme.colors.black};
    text-decoration: none;
  }

  @media (min-width: 992px) {
    padding-top: 60px;
    padding-bottom: 80px;
  }

  @media (min-width: 1200px) {
    padding-top: 100px;
  }
`;

const SectionTitle = styled.div`
  border-bottom: 1px solid ${({theme}) => theme.colors.black};
  margin-bottom: 40px;
`;

const MainTitle = styled.p`
  font-size: 1.25rem;
  line-height: 2.75rem;
  font-weight: 700;
  color: ${({theme}) => theme.colors.black};
  margin-bottom: 0;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 60px;
  margin-bottom: 25px;

  @media (min-width: 992px) {
    grid-template-columns: 1fr 1fr;
    margin-bottom: 0;
  }

  @media (min-width: 1200px) {
    gap: 125px;
  }
`;

const InfoBox = styled.div`
  ${MainTitle} {
    color: ${({theme}) => theme.colors.primary};
  }
`;

const InfoContent = styled.div`
  max-width: 425px;
  margin: 0 auto;
`;

const PlanWrapper = styled.div`
`;

const TagsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-bottom: 40px;

  & > div {
    background-color: ${({theme}) => theme.colors.primary};
    padding: 0 16px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: ${({theme}) => theme.colors.white};
    font-size: 1rem;
    line-height: 1.75rem;
    font-weight: 700;
    border-radius: 50px;
  }

  @media (min-width: 992px) {
    gap: 15px;

    & > div {
      padding: 0 20px;
      height: 50px;
      font-size: 1.25rem;
      line-height: 2.75rem;
    }
  }
`;

const LegentTitle = styled.p`
  font-weight: 700;
  line-height: 1.75rem;
  margin-bottom: 20px;
`;

const SumTitle = styled(LegentTitle)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 32px;
`;


const ItemList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 20px;
  line-height: 1.125rem;
`;

const ItemRow = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr max-content;
  column-gap: 10px;

  @media (min-width: 992px) {
    grid-template-columns: 50px 1fr max-content;
    column-gap: 18px;
  }
`;

const Number = styled.span`
  color: ${({theme}) => theme.colors.primary};
  font-weight: 700;
`;

const Value = styled.span`
  text-align: right;

  @media (min-width: 992px) {
    padding-left: 2rem;
  }
`;

const Note = styled.p`
  font-size: 0.875rem;
  line-height: 1.5rem;
  margin-bottom: 0;

  @media (min-width: 992px) {
    margin-bottom: 40px;
  }
`;

const PriceWrapper = styled.div`
  background-color: ${({theme}) => theme.colors.primary};
  height: 50px;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  color: ${({theme}) => theme.colors.white};
  font-size: 1.25rem;
  font-weight: 700;
  border-radius: 50px;
  margin-top: 30px;
  margin-bottom: 30px;
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 992px) {
    margin-bottom: 24px;
  }
`;

const ActionBtns = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-bottom: 32px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
    gap: 16px;
  }
  @media (min-width: 992px) {
    justify-content: space-between;
    margin-bottom: 32px;
  }
`;

const ArrowBtn = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  cursor: pointer;

  span {
    font-size: 1.25rem;
    line-height: 1.625rem;
    font-weight: 700;
  }

  &.es-download-link {
    ${ArrowIcon} {
      transform: rotateZ(-90deg);
    }
  }

  &:hover {
    ${Bullet} {
      background-color: ${({theme}) => theme.colors.primary_hover};
    }
  }
`;

const PlanImgWrapper = styled.div`
  max-width: 650px;
  margin: 0 auto;

  .es-plan-img {
    width: 100%;
    height: auto;
    margin-bottom: 30px;
  }
`;

const CompassImg = styled.img`
  display: block;
  width: 100px;
  height: auto;
  margin: 0 auto;

  @media (min-width: 992px) {
    margin-left: auto;
    margin-right: 0;
  }
`;

const AtelierNote = styled.p`
  margin-bottom: 0;
`;

function DetailInfoSection({data}) {
  const isMobile = useMediaQuery({
    query: '(max-width: 991px)'
  });

  return (
    <Section>
      <ContentWrapper>
        <Grid>
          <InfoBox>
            <SectionTitle>
              <MainTitle as="h1">
                {data.isAtelier ? `Ateliér ${data.id}` : `Byt ${data.id}`}
              </MainTitle>
            </SectionTitle>
            <InfoContent>
              <TagsWrapper>
                <div>
                  <span>{data.floor}&nbsp;{data.isAtelier ? 'PP' : 'NP'}</span>
                </div>
                <div>
                  <span>{data.type}</span>
                </div>
                <div>
                  <span>{new Intl.NumberFormat('cs-CZ').format(data.sum_area)}&nbsp;m<sup>2</sup></span>
                </div>
              </TagsWrapper>
              <LegentTitle>
                Legenda místností
              </LegentTitle>
              <ItemList>
                {
                  data.rooms.map(it => (
                    <ItemRow key={it.id}>
                      {
                        data.isAtelier ? (
                          <Number>{`${data.id}.${it.id}`}</Number>
                        ) : (
                          <Number>{`${data.id}0${it.id}`}</Number>
                        )
                      }
                      <span>{it.label}</span>
                      <Value>{new Intl.NumberFormat('cs-CZ', { minimumFractionDigits: 2 }).format(it.value)}&nbsp;m<sup>2</sup></Value>
                    </ItemRow>
                  ))
                }
              </ItemList>
              <SumTitle>
                <span>Podlahová plocha*</span>
                <span>{new Intl.NumberFormat('cs-CZ').format(data.sum_area)}&nbsp;m<sup>2</sup></span>
              </SumTitle>
              <ItemList>
                {
                  data.balcony && (
                    <ItemRow>
                      <Number></Number>
                      <span>balkon</span>
                      <Value>{new Intl.NumberFormat('cs-CZ',  { minimumFractionDigits: 2 }).format(data.balcony)}&nbsp;m<sup>2</sup></Value>
                    </ItemRow>
                  )
                }
                {
                  data.terrace && (
                    <ItemRow>
                      <Number></Number>
                      <span>terasa</span>
                      <Value>{new Intl.NumberFormat('cs-CZ',  { minimumFractionDigits: 2 }).format(data.terrace)}&nbsp;m<sup>2</sup></Value>
                    </ItemRow>
                  )
                }
                {
                  data.garden && (
                    <ItemRow>
                      <Number></Number>
                      <span>zahrada</span>
                      <Value>{new Intl.NumberFormat('cs-CZ',  { minimumFractionDigits: 2 }).format(data.garden)}&nbsp;m<sup>2</sup></Value>
                    </ItemRow>
                  )
                }
              </ItemList>
              <LegentTitle>
                Volitelné příslušenství
              </LegentTitle>
              <ItemList>
                {
                  data.cellar  && (
                    <div>sklep</div>
                  )
                }
                <div>parkovací stání</div>
              </ItemList>

              {
                !isMobile && (
                  <PriceWrapper>
                    <span>{data.isAtelier ? 'Cena ateliéru' : 'Cena bytu'}</span>
                    <span>{data.price > 0 ? new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: 'CZK', minimumFractionDigits: 0 }).format(Math.round(data.price)) : '-'}</span>
                  </PriceWrapper>
                )
              }
              <Note>
                *celková podlahová plocha dle vyhlášky č.&nbsp;366/2013
              </Note>
              {
                !isMobile && (
                  <>
                    <ActionBtns>
                      <a href={`/data/pdf/${data.id}.pdf`} title="Stáhnout kartu v PDF" target="_blank" rel="noreferrer">
                        <ArrowBtn className="es-download-link">
                          <span>Karta bytu</span>
                          <Bullet>
                            <ArrowIcon />
                          </Bullet>
                        </ArrowBtn>
                      </a>
                      <AnchorLink to={`#${ANCHOR_CONTACT}`}>
                        <ArrowBtn>
                          <span>Domluvit prohlídku</span>
                          <Bullet>
                            <ArrowIcon />
                          </Bullet>
                        </ArrowBtn>
                      </AnchorLink>
                    </ActionBtns>
                    {
                      data.isAtelier && (
                        <AtelierNote>
                          Připravili jsme pro vás cenově dostupnou variantu bydlení v&nbsp;suterénu bytového domu, která nabízí přístup přirozeného světla prostřednictvím světlíků a&nbsp;je plně funkční pro pohodlné bydlení.
                        </AtelierNote>
                      )
                    }
                  </>
                )
              }
            </InfoContent>
          </InfoBox>
          <PlanWrapper>
            <SectionTitle>
              <MainTitle as="h2">
                Půdorys
              </MainTitle>
            </SectionTitle>
            <PlanImgWrapper>
              <img className="es-plan-img" src={`/data/plan/${data.id}.jpg`} alt={`Obrázek - půdorys byt ${data.id}`} />
              <CompassImg src={compassSrc} alt="Směrovka" />
            </PlanImgWrapper>
          </PlanWrapper>
        </Grid>
        {
          isMobile && (
            <>
              <PriceWrapper>
                <span>{data.isAtelier ? 'Cena ateliéru' : 'Cena bytu'}</span>
                <span>{data.price > 0 ? new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: 'CZK', minimumFractionDigits: 0 }).format(Math.round(data.price)) : '-'}</span>
              </PriceWrapper>
              <ActionBtns>
                <a href={`/data/pdf/${data.id}.pdf`} title="Stáhnout kartu v PDF" target="_blank" rel="noreferrer">
                  <ArrowBtn className="es-download-link">
                    <span>Karta bytu</span>
                    <Bullet>
                      <ArrowIcon />
                    </Bullet>
                  </ArrowBtn>
                </a>
                <AnchorLink to={`#${ANCHOR_CONTACT}`}>
                  <ArrowBtn>
                    <span>Domluvit prohlídku</span>
                    <Bullet>
                      <ArrowIcon />
                    </Bullet>
                  </ArrowBtn>
                </AnchorLink>
              </ActionBtns>
              {
                data.isAtelier && (
                  <AtelierNote>
                    Připravili jsme pro vás cenově dostupnou variantu bydlení v&nbsp;suterénu bytového domu, která nabízí přístup přirozeného světla prostřednictvím světlíků a&nbsp;je plně funkční pro pohodlné bydlení.
                  </AtelierNote>
                )
              }
            </>
          )
        }
      </ContentWrapper>
    </Section>
  );
}

export default DetailInfoSection;