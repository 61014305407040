import React from 'react';
import styled from 'styled-components';
import { Section as DefaultSection } from '../section';
import { Title3 } from '../Headings';
import GalleryPanel from '../GalleryPanel';

const Section = styled(DefaultSection)`
  padding-top: 40px;

  ${Title3} {
    text-align: center;
    margin-bottom: 40px;
  }

  @media (min-width: 1200px) {

    ${Title3} {
      margin-bottom: 60px;
    }
  }
`;

function DetailGallerySection() {
  return (
    <Section>
      <Title3 as="h2">
        Galerie
      </Title3>
      <GalleryPanel folder="atelier" data={[1,2,3,4]} />
    </Section>
  );
}

export default DetailGallerySection;