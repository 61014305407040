import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { useMediaQuery } from 'react-responsive';
import SectionWrapper from '../sectionWrapper';
import { Section as DefaultSection } from '../section';
import { ContentWrapper } from '../common';
import { Title2 } from '../Headings';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { ANCHOR_CONTACT } from '../../routes';
import arrowSvg from '../../images/svg/arrow-left.svg';

const ArrowIcon = styled(arrowSvg)`
  fill: ${({theme}) => theme.colors.white};
  transform: rotateZ(-180deg);
`;

const Bullet = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 100px;
  background-color: ${({theme}) => theme.colors.primary};
  transition: all ease 0.3s;
`;

const Section = styled(DefaultSection)`
  padding: 40px 0 50px;

  @media (min-width: 992px) {
    padding: 55px 0;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  aspect-ratio: 1;
  border-radius: 600px;
  order: 2;

  .gatsby-image-wrapper {
    height: 100%;
    border-radius: 600px;
  }

  .cover-img {
    object-fit: cover;
  }

  @media (min-width: 1200px) {
    height: 590px;
    width: auto;
  }
`;

const InfoBox = styled.div`
  order: 1;

  ${Title2} {
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 0;
    line-height: 1.875rem;
  }

  a, a:hover {
    display: inline;
    color: ${({theme}) => theme.colors.black};
    text-decoration: none;
  }

  @media (min-width: 768px) {
    p {
      margin-bottom: 40px;
    }
  }

  @media (min-width: 992px) {
    ${Title2} {
      margin-bottom: 60px;
    }

    p {
      max-width: 390px;
      margin-bottom: 60px;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 40px;
  align-items: center;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;

    ${ImageWrapper} {
      order: 1;
    }

    ${InfoBox} {
      order: 2;
    }
  }

  @media (min-width: 1200px) {
    grid-template-columns: 590px 1fr;
    gap: 70px;
  }
`;

const MobileLinkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 40px;
`;

const LinkContent = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0 auto;
  gap: 10px;

  span {
    font-size: 1.25rem;
    line-height: 1.625rem;
    font-weight: 700;
    color: ${({theme}) => theme.colors.black};
  }

  &:hover {
    ${Bullet} {
      background-color: ${({theme}) => theme.colors.primary_hover};
    }
  }
`;

function InterestSection() {
  const isMobile = useMediaQuery({
    query: '(max-width: 767px)'
  });

  return (
    <SectionWrapper $bgColor="primary_muted">
      <Section>
        <ContentWrapper>
          <Grid>
            <ImageWrapper>
              <StaticImage
                imgClassName="cover-img"
                src="../../images/img-detail-01.jpg" layout="fullWidth" placeholder="blurred" alt="Foto" />
            </ImageWrapper>
            <InfoBox>
              <Title2>
                Máte<br />zájem?
              </Title2>
              <p>Nemusíte mít strach a&nbsp;bát se nesnází, naši experti s&nbsp;hypotékou pomohou, ať vše dobře dopadne a&nbsp;úsměv se rozzáří.</p>
              {
                !isMobile && (
                  <AnchorLink to={`#${ANCHOR_CONTACT}`}>
                    <LinkContent>
                      <span>Domluvit prohlídku</span>
                      <Bullet>
                        <ArrowIcon />
                      </Bullet>
                    </LinkContent>
                  </AnchorLink>
                )
              }
            </InfoBox>
          </Grid>
          {
            isMobile && (
              <MobileLinkWrapper>
                <AnchorLink to={`#${ANCHOR_CONTACT}`}>
                  <LinkContent>
                    <span>Domluvit prohlídku</span>
                    <Bullet>
                      <ArrowIcon />
                    </Bullet>
                  </LinkContent>
                </AnchorLink>
              </MobileLinkWrapper>
            )
          }
        </ContentWrapper>
      </Section>
    </SectionWrapper>
  );
}

export default InterestSection;