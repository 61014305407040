import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import Seo from '../components/seo';
import Layout from '../components/Layout';
import InterestSection from '../components/InterestSection';
import DetailInfoSection from '../components/DetailInfoSection';
import DetailGallerySection from '../components/DetailGallerySection';

function DetailPage({data}) {
  const pageData = data.allSitePage.edges[0].node.pageContext;

  useEffect(() => {
    window.scrollTo(0,0);
  },[]);

  return (
    <Layout>
      <Seo title={pageData.isAtelier ? `Ateliér ${pageData.id}` : `Byt ${pageData.id}`} />
      <DetailInfoSection data={pageData} />
      {
        pageData.isAtelier && (
          <DetailGallerySection />
        )
      }
      <InterestSection />
    </Layout>
  );
}

export default DetailPage;

export const query = graphql`
  query($path: String!) {
    allSitePage(filter: {path: { eq: $path } } ) {
      edges {
        node {
          pageContext
        }
      }
    }
  }
`;